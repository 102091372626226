@tailwind base;
@tailwind components;
@tailwind utilities;


:root, #root, body, html {
  height: 100%;
  margin: 0;
  top: 0;
  width: 100%;
}

body {
  background: 
    linear-gradient(
      90deg, 
      rgba(87,19,19,1) 0%, 
      rgba(122,27,44,1) 50%, 
      rgba(87,19,19,1) 100%
    );
  color: #f3e2d2;
  /* font: 'serif'; */
}

* {
  box-sizing: border-box;
  text-align: center;
}

.inactiveDD {
  display: none;
}

@layer components {
  .inactiveNav {
    height: 0;
    position: absolute;

  }
  .active {
    height: auto !important;
    margin-top: 80px !important;
    opacity: 1 !important;
  }
}

.swiper-container {
  width: 100%;
  /* max-height: 100vh; */
}

.swiper-slide {
  height: -webkit-max-content!important;
  height: -moz-max-content!important;
  height: max-content!important;
}

.swiper {
  /* width: 600px; */
  height: 700px;
}

.swiper-slide {
/* background-color: grey; */
height: auto !important;
text-align: center;
}
.swiper-content {
height: 100% !important
}


/* TO SET BACKGROUND WHEN SCROLLING PAST */
/* 
background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.5, #8BC63E),
    color-stop(0.5, #EEEEEE)
);
background: -o-linear-gradient(bottom, #8BC63E 50%, #EEEEEE 50%);
background: -moz-linear-gradient(bottom, #8BC63E 50%, #EEEEEE 50%);
background: -webkit-linear-gradient(bottom, #8BC63E 50%, #EEEEEE 50%);
background: -ms-linear-gradient(bottom, #8BC63E 50%, #EEEEEE 50%);
background: linear-gradient(to bottom, #8BC63E 50%, #EEEEEE 50%);
*/